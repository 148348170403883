.slogan {
    line-height: 1.4;   
    margin: 0;
}

.slogan a {
    color: darken(@brand-primary, 10%);   
}

.top {
	position: relative;
	width:100%;
	background-color: @header;
	padding-top: 20px;
}
.divider {
	position: relative;
	width:100%;
	height:10px;
	background-color: @divider;
	-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
           -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.divider:before,
.divider:after {
	content:"";
    position:absolute; 
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.divider:after {
	right:10px; 
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg); 
       -moz-transform:skew(8deg) rotate(3deg);     
        -ms-transform:skew(8deg) rotate(3deg);     
         -o-transform:skew(8deg) rotate(3deg); 
            transform:skew(8deg) rotate(3deg);
}
.slider {
	width:100%;
	height:400px;
	background-color:#ffffff;
}
.header {
	width:100%;
	height:auto;
	background-color:#FFF;
}

.logo {
	position: relative;
}
.logo-img {
	z-index: 99;
}
.logo-text {
	padding-top: 22px;
	position: absolute;
	top: 0;
	left: 15px;
}
.slogan h3 {
	color: @brand-primary;
}
.main-content {
	margin-top: 30px;
}
.middle-content {
	width: 100%;
	margin: 40px 0;
	background: @brand-primary;

	h2, a {
		color: @headings-alt;
	}
}
.bottom-content {
	margin-bottom: 40px;
}
.button-clear {
	width: 150px;
	height: 30px;
	border: 1px solid #fff;
	padding-top: 5px;
	margin: 0 auto 20px auto;
	border-radius: 10px;
}
.footer-bg {
	width: 100%;
	min-height: 100px;
	background-color: @footer;
	box-shadow: 0 0 10px 1px lighten(@footer, 15%);
	padding: 20px 0;
	margin-top: 40px;
}
.bottom-bar {
	width: 100%;
	height: 8px;
	background-color: @brand-primary;
}
.copyright {
	padding-top: 30px;
}
.lime {
	padding-top: 10px;
}
.top-img {
	padding-bottom: 20px;
}
.acceditation {
	img {
		padding-left: 20px;
		-webkit-filter: grayscale(100%);
		.transition(.5s);
	}
}
.acceditation {
	img:hover {
		-webkit-filter: grayscale(0%);
		.transition(.5s);
	}
}



.get {
    display:none;
}
#diagram {
    // float:right;
    width:600px;
    height:600px;
}
.legend {
// float:right;
width:250px;
margin-top:140px;
}
.skills {
float:left;
clear:both;
width:100%;
}

.skills ul,
.skills li {
display:block;
list-style:none;
margin:0;
padding:0;
}

.skills li {
float:right;
clear:both;
padding:0 15px;
height:35px;
line-height:35px;
color:#fff;
margin-bottom:1px;
font-size:18px;
}

.skills .jq {
background:#97BE0D;
}

.skills .css {
background:#D84F5F;
}

.skills .html {
background:#88B8E6;
}

.skills .php {
background:#BEDBE9;
}

.skills .sql {
background:#EDEBEE;
}